import React, { useState } from 'react';
import ProgressBar from './components/ProgressBar';
import Log from './components/Log';

const MAX_DURATION = 360; // Includes the free trial period
const FREE_TRIAL = 31;
const KEY_PRICE = 1; // 1 ETH

function App() {
  const [aliceDuration, setAliceDuration] = useState<number>(0);
  const [bobDuration, setBobDuration] = useState<number>(0);
  const [aliceBalance, setAliceBalance] = useState<number>(10); // Starting balance
  const [bobBalance, setBobBalance] = useState<number>(0);
  const [logs, setLogs] = useState<string[]>([]);
  const [bobTrialDuration, setBobTrialDuration] = useState<number>(0);
  const [aliceTrialDuration, setAliceTrialDuration] = useState<number>(0);

  const addLog = (message: string) => {
    setLogs((prevLogs: string[]) => [...prevLogs, message]);
  };

  const alicePurchase = () => {
    if (aliceBalance >= KEY_PRICE) {
      setAliceDuration((prev) => prev + MAX_DURATION);
      setAliceBalance((prev) => prev - KEY_PRICE);
      setAliceTrialDuration(FREE_TRIAL);
      addLog(`Alice purchased the key for ${KEY_PRICE} ETH and received ${MAX_DURATION} days (including ${FREE_TRIAL} trial days).`);
    } else {
      addLog("Alice doesn't have enough balance to purchase.");
    }
  };

  const aliceShare = () => {
    if (aliceDuration >= 30) {
      const aliceRemainingTrial = Math.min(aliceTrialDuration, 30);
      const sharedTrial = aliceRemainingTrial;
      const sharedPaid = 30 - sharedTrial;

      setAliceDuration((prev) => prev - 30);
      setBobDuration((prev) => prev + 30);

      // Adjust Alice's remaining trial
      setAliceTrialDuration((prev) => Math.max(0, prev - sharedTrial));

      // Adjust Bob's trial duration
      setBobTrialDuration((prev) => prev + sharedTrial);

      addLog(`Alice shared 30 days with Bob (${sharedTrial} trial days, ${sharedPaid} paid days).`);
      addLog(`Alice's remaining trial: ${Math.max(0, aliceTrialDuration - sharedTrial)} days.`);
    } else {
      addLog("Alice doesn't have enough days to share.");
    }
  };

  const aliceCancel = () => {
    if (aliceDuration > 0) {
      setAliceBalance((prev) => prev + KEY_PRICE);
      setAliceDuration(0);
      setAliceTrialDuration(0);
      addLog(`Alice canceled and received a refund of ${KEY_PRICE} ETH.`);
    } else {
      addLog("Alice has no key to cancel.");
    }
  };

  const bobCancel = () => {
    if (bobDuration > 0) {
      setBobBalance((prev) => prev + KEY_PRICE);
      setBobDuration(0);
      setBobTrialDuration(0); // Reset Bob's trial duration
      addLog(`Bob canceled and received a refund of ${KEY_PRICE} ETH.`);
    } else {
      addLog("Bob has no key to cancel.");
    }
  };

  return (
    <div className="App">
      <h1>Unlock Refund Trial Demo</h1>
      <p>Alice:</p>
      <ProgressBar 
        duration={aliceDuration} 
        maxDuration={MAX_DURATION} 
        trialDuration={aliceTrialDuration}
        balance={aliceBalance}
        keyPrice={KEY_PRICE}
      />
      <p>Bob:</p>
      <ProgressBar 
        duration={bobDuration} 
        maxDuration={MAX_DURATION}
        trialDuration={bobTrialDuration}
        balance={bobBalance}
        keyPrice={KEY_PRICE}
      />

      <button onClick={alicePurchase}>Alice Purchase Key</button>
      <button onClick={aliceShare}>Alice Share 30 Days</button>
      <button onClick={aliceCancel}>Alice Cancel & Refund</button>
      <button onClick={bobCancel}>Bob Cancel & Refund</button>

      <Log logs={logs} />
    </div>
  );
}

export default App;