import React from 'react';

interface ProgressBarProps {
  duration: number;
  maxDuration: number;
  trialDuration: number;
  balance: number;
  keyPrice: number;
}

const ProgressBar: React.FC<ProgressBarProps> = ({ duration, maxDuration, trialDuration, balance, keyPrice }) => {
  const totalPercentage = Math.min((duration / maxDuration) * 100, 100);
  const trialPercentage = Math.min((Math.min(duration, trialDuration) / maxDuration) * 100, totalPercentage);
  const regularPercentage = totalPercentage - trialPercentage;

  return (
    <div style={{ width: '100%', maxWidth: '500px', margin: '0 auto' }}>
      <div style={{ 
        width: '100%', 
        height: '20px', 
        backgroundColor: '#e0e0e0', 
        borderRadius: '5px',
        overflow: 'hidden',
        display: 'flex'
      }}>
        <div style={{
          width: `${trialPercentage}%`,
          height: '100%',
          backgroundColor: '#4CAF50', // Green for trial period
          transition: 'width 0.5s ease-in-out',
        }} />
        <div style={{
          width: `${regularPercentage}%`,
          height: '100%',
          backgroundColor: '#2196F3', // Blue for regular period
          transition: 'width 0.5s ease-in-out',
        }} />
      </div>
      <div style={{ textAlign: 'center', marginTop: '5px' }}>
        {duration} / {maxDuration} days (Trial: {Math.min(duration, trialDuration)} / {trialDuration} days)
      </div>

      <div style={{ textAlign: 'center', marginTop: '5px' }}>
        Balance: {balance.toFixed(2)} ETH | Key Price: {keyPrice} ETH
      </div>
    </div>
  );
};

export default ProgressBar;