import React from 'react';

interface LogProps {
  logs: string[];
}

function Log({ logs }: LogProps) {
  return (
    <div className="log">
      {logs.map((log, index) => (
        <p key={index}>{log}</p>
      ))}
    </div>
  );
}

export default Log;